import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { TextField, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const ContactForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data: any) => {
    try {
      // Replace 'your_service_id', 'your_template_id', and 'your_user_id' with your actual EmailJS service ID, template ID, and user ID
      await emailjs.send(
        "service_txgqssu",
        "template_yk83p2e",
        data,
        "_qFnsKmTI3UUOlogT"
      );
      alert("Your message has been sent!");
      reset(); // Clear the form fields
      navigate("/");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send the message.");
      navigate("/");
    }
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ height: "60vh" }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Email Input */}
          <TextField
            label="Your Email"
            variant="outlined"
            fullWidth
            {...register("user_email", {
              required: "Email is required",
              pattern: {
                value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.user_email}
            helperText={
              errors.user_email ? (errors.user_email.message as string) : ""
            } // Type assertion here
            margin="normal"
            sx={{
              "& .MuiFormLabel-root": {
                color: "#111111", // Label color
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#111111", // Label color when focused
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color when focused
                },
                "& .MuiInputBase-input": {
                  color: "#111111", // Input text color
                },
              },
            }}
          />

          <TextField
            label="Leave comment or report broken link / ask for subtitles"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            {...register("comment", { required: "Comment is required" })}
            error={!!errors.comment}
            helperText={
              errors.comment ? (errors.comment.message as string) : ""
            } // Type assertion here
            margin="normal"
            sx={{
              "& .MuiFormLabel-root": {
                color: "#111111", // Label color
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#111111", // Label color when focused
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color when focused
                },
                "& .MuiInputBase-input": {
                  color: "#111111", // Input text color
                },
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#111111" }}
          >
            Send
          </Button>
        </form>
      </Container>
    </>
  );
};

export default ContactForm;
