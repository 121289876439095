import React, { useState, useEffect } from "react";
import "./SortAndSearchBar.css";
import AppDropdown from "./AppDropdown";
import AppFilterDropdown from "./AppFilterDropdown";
import AppSearch from "./AppSearch";
import ToggleDocSize from "./ToogleDocSize";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface ISortAndSearchBar {
  setFilterBy: (filterBy: string | null) => void;
  setSortBy: (sortBy: string | null) => void;
  searchTitles: { title: string; _id: string }[];
  alignment: boolean;
  setAlignment: (alignment: boolean) => void;
}

const SortAndSearchBar: React.FC<ISortAndSearchBar> = ({
  setFilterBy,
  setSortBy,
  searchTitles,
  alignment,
  setAlignment,
}) => {
  const isNotMobile = useMediaQuery("(min-width:601px)");
  const [opacity, setOpacity] = useState(1); // Initialize opacity state
  const [scrollTimeout, setScrollTimeout] = useState<number | undefined>(
    undefined
  );

  // Scroll handler to adjust opacity while scrolling
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const maxScroll = 200; // Maximum scroll value to control opacity fading

    const newOpacity = Math.max(1 - scrollTop / maxScroll, 0); // Minimum opacity is set to 0
    setOpacity(newOpacity);

    // Clear the previous timeout if the user is still scrolling
    if (scrollTimeout) clearTimeout(scrollTimeout);

    // Set a new timeout to restore opacity after scrolling stops (e.g., after 350ms)
    const newTimeout = window.setTimeout(() => {
      setOpacity(1); // Fully restore the opacity after scroll stops
    }, 350);

    setScrollTimeout(newTimeout);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);

      // Clear timeout on unmount
      if (scrollTimeout) clearTimeout(scrollTimeout);
    };
  }, [scrollTimeout]);

  return (
    <div
      className="sort-and-search-bar"
      style={{
        opacity,
        transition: "opacity 0.3s ease-out", // Apply smooth transition
      }}
    >
      <div className="search-container">
        <AppSearch searchTitles={searchTitles} />
      </div>
      <div className="dropdown-container">
        <AppFilterDropdown setFilterBy={setFilterBy} />
      </div>
      <div className="dropdown-container">
        <AppDropdown setSortBy={setSortBy} />
      </div>
    </div>
  );
};

export default SortAndSearchBar;
