import React, { useEffect, useState } from "react";
import { IBook, IUserData } from "../models/models";
import { checkIfUserIsLogged, getData } from "../api/getData";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/system";
import {
  Alert,
  Card,
  CardMedia,
  Grid,
  Rating,
  Typography,
  Button,
} from "@mui/material";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
import Loading from "../components/Loading";
import AddModal from "../components/AddModal";
import { getRandomLink } from "../utlis/randomLink";
import Footer from "../components/Footer";

interface ISingleBookProps {
  setUserData: (userData: IUserData) => void;
  userData: IUserData;
}

const SingleBook: React.FC<ISingleBookProps> = ({ setUserData, userData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [singleBook, setSingleBook] = useState<IBook>({} as IBook);
  const [isVisible, setIsVisible] = useState(true);
  const randomLink: string = getRandomLink();

  const { id } = useParams<{ id: string }>();

  const getSingleBook = async () => {
    try {
      setIsLoading(true);

      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }

      const response = await getData("books", id);

      setSingleBook(response);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (action: string, url?: string) => {
    window.open(isVisible ? randomLink : url, "_blank", "noopener,noreferrer");

    setIsVisible(false);
  };

  useEffect(() => {
    getSingleBook();
    window.scrollTo(0, 0);
  }, [id]);

  if (requestError)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>

        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  if (isLoading) return <Loading text="Loading your book..." />;

  return (
    <>
      <Container sx={{ mt: 4, mb: 4 }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Image on top for small screens
            maxWidth: 920,
            mx: "auto",
            boxShadow: 3,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            image={singleBook.image}
            alt={singleBook.title}
            sx={{
              width: { xs: "100%", sm: 300 },
              height: { xs: "100%", sm: "auto" },
              objectFit: "cover",
            }}
          />

          <Box sx={{ p: 3, display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              {singleBook.title}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, lineHeight: 1.5 }}
            >
              {singleBook.description}
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Author:</strong> {singleBook?.author}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ display: "flex" }}>
                  <strong>Rating:</strong>{" "}
                  <Rating
                    readOnly
                    value={singleBook?.rating}
                    size="small"
                    sx={{ marginLeft: "2px" }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Genre:</strong>{" "}
                  {capitalizeFirstChar(singleBook?.genre)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Pages:</strong> {singleBook.pages}
                </Typography>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#3c3c3c", // Hover color
                },
                "&:active": {
                  backgroundColor: "#3c3c3c", // Click color
                },
              }}
              onClick={() => handleClick("download", singleBook?.shareLink)}
            >
              {`Download ${
                singleBook?.fullBook ? "Book" : "Sample"
              } (mobi / kfx)`}
            </Button>
            {isVisible && (
              <Typography sx={{ fontSize: "0.7rem", marginBottom: "-6px" }}>
                First click will open ad
              </Typography>
            )}
            {/* Additional Button */}
            <Button
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "#f1f1f1",
                "&:hover": {
                  backgroundColor: "lightgrey", // Hover color
                },
                "&:active": {
                  backgroundColor: "lightgrey", // Click color
                },
                color: "black",
              }}
              onClick={() => handleClick("other", singleBook?.otherSources)}
            >
              Other Sources
            </Button>
            <a
              style={{
                textDecoration: "none",
                fontSize: "0.8rem",
                marginTop: "40px",
                color: "black",
              }}
              href="https://cloudconvert.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for suggested online ebook converter
            </a>
          </Box>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default SingleBook;
