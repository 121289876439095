import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { IDoc, IUserData } from "../models/models";
import {
  checkIfUserIsLogged,
  getData,
  handleFavouritesApi,
} from "../api/getData";
import {
  Container,
  Grid,
  Typography,
  Box,
  Rating,
  Paper,
  Chip,
  Button,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import AppVideoPlayer from "../components/AppVideoPlayer";
import ShareModal from "../components/ShareModal";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
import { keyframes } from "@mui/system";
import MoreLikeThis from "../components/MoreLikeThis";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { checkIsFavourite } from "../utlis/checkIsFavourite";

const glareAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export interface ISingleDoc {
  setNumberOfDocs: (docsNumber: number) => void;
  docList: IDoc[];
  typeOfDocs: string;
  setUserData: (userData: IUserData) => void;
  userData: IUserData;
}

const SingleDoc: React.FC<ISingleDoc> = ({
  setNumberOfDocs,
  docList,
  typeOfDocs,
  setUserData,
  userData,
}) => {
  const [singleDoc, setSingleDoc] = useState<IDoc>({} as IDoc);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [isFavourite, setIsfavourite] = useState<boolean>(false);

  const location = useLocation();

  let urlToShare = window.location.origin + location.pathname;
  const currentUrl = window.location.href;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  if (!typeOfDocs) typeOfDocs = "docs";
  if (currentUrl.includes("singleTalk")) typeOfDocs = "talks";

  const getSingleDoc = async () => {
    try {
      setIsLoading(true);

      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }

      const response = await getData(typeOfDocs, id);

      setSingleDoc(response);
      setIsLoading(false);
      setNumberOfDocs(1);
    } catch (err) {
      console.log(err);
      setRequestError(true);
      setNumberOfDocs(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFavourite = async () => {
    if (!userData?.isLogged) return;
    try {
      let favourite = {
        _id: singleDoc?._id,
        title: singleDoc?.title,
        image: singleDoc?.image,
        type: typeOfDocs,
      };

      const action = isFavourite ? "delete" : "add";
      const response = await handleFavouritesApi(
        userData?.email,
        action,
        favourite
      );
      if (response) {
        setIsfavourite(!isFavourite); // Toggle state locally after API success
        console.log(response);
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log("An unknown error occurred.");
      }
    }
  };

  useEffect(() => {
    getSingleDoc();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (singleDoc && userData) {
      setIsfavourite(checkIsFavourite(userData, singleDoc, typeOfDocs));
    }
  }, [singleDoc, userData, typeOfDocs]);

  if (!singleDoc)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={getSingleDoc}
          sx={{ mb: 2 }}
        >
          Retry
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mb: 2, marginLeft: 2 }}
        >
          Home
        </Button>
        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          style={{
            backgroundColor: "#111111",
            marginTop: "-48px",
            marginBottom: "-24px",
          }}
        >
          <Container sx={{ mt: 4, marginBottom: "24px", marginTop: "-16px" }}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <img
                    src={singleDoc?.image}
                    alt={singleDoc?.title}
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                  <Typography variant="h6" component="h2" gutterBottom>
                    Description
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {singleDoc?.description}
                  </Typography>

                  <Typography variant="h6" component="h2" gutterBottom>
                    Tags
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {singleDoc?.tags.map((tag: any, index: any) => (
                      <Tooltip
                        title="Find more docs with this tag"
                        arrow
                        placement="top"
                        key={index.toString() + tag.toString()}
                      >
                        <Chip
                          key={index.toString() + tag.toString()}
                          label={tag}
                          onClick={() => navigate(`/tag/${tag}`)}
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    {singleDoc?.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Rating readOnly value={singleDoc?.rating} />
                    <Tooltip
                      title="This is webpage editor's rating"
                      arrow
                      placement="top"
                      // No additional customization here for basic usage
                    >
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        ({singleDoc?.rating})
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Duration
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {singleDoc?.duration} minutes
                  </Typography>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Genre
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {capitalizeFirstChar(singleDoc?.genre)}
                  </Typography>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Year
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {singleDoc?.year}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ marginRight: "6px" }}>
                      Enjoyed the doc?
                    </Typography>
                    <Button
                      onClick={handleOpen}
                      sx={{
                        backgroundColor: "#2196f3",
                        color: "#fff",
                        border: "2px solid transparent", // Set a border
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: "-150%",
                          width: "250%",
                          height: "100%",
                          background:
                            "linear-gradient(120deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.1) 100%)",
                          backgroundSize: "200% 100%",
                          animation: `${glareAnimation} 3s infinite`,
                          zIndex: 1,
                        },
                        "&:hover": {
                          backgroundColor: "#1976d2", // Slightly darker on hover
                        },
                        transition: "0.3s",
                      }}
                    >
                      Share <ShareIcon />
                    </Button>
                    <Tooltip
                      title={
                        userData?.isLogged
                          ? isFavourite
                            ? "Remove from favs"
                            : "Add to favorites"
                          : "Log in to add favorites"
                      }
                      placement="top"
                    >
                      <IconButton
                        onClick={handleFavourite}
                        size="large"
                        sx={{
                          color: isFavourite ? "red" : "lightgray",
                          marginLeft: "8px",
                        }}
                        aria-label="add to favorites"
                      >
                        <FavoriteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <ShareModal
                    open={open}
                    handleClose={handleClose}
                    url={urlToShare}
                    image={singleDoc.image}
                    title={singleDoc.title}
                    description={singleDoc.description}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <AppVideoPlayer
                  docUrl={singleDoc.shareLink}
                  previewImg={singleDoc.image}
                  subs={singleDoc?.subs}
                />
              </Box>
              <MoreLikeThis
                docList={docList}
                tags={singleDoc?.tags}
                singleId={singleDoc?._id}
                typeOfDocs={typeOfDocs}
              />
            </Paper>
          </Container>
        </div>
      )}
      <Footer />
    </>
  );
};

export default SingleDoc;
