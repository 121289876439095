import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorPage = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 8 }}>
      <ErrorOutlineIcon color="error" sx={{ fontSize: 100 }} />
      <Typography variant="h1" component="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, the page you are looking for does not exist. You can always go
        back to the homepage.
      </Typography>
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
        >
          Home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
