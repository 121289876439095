import { IDoc, IUserData } from "../models/models";

export const checkIsFavourite = (
  userData: IUserData,
  docInfo: IDoc,
  typeOfDocs: string
): boolean => {
  let isFavourite = false;
  for (let i = 0; i < userData?.favourites?.length; i++) {
    if (
      userData?.favourites[i]._id === docInfo?._id &&
      userData?.favourites[i].type === typeOfDocs
    ) {
      isFavourite = true;
      return isFavourite;
    }
  }
  return isFavourite;
};
