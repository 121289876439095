import React, { useState } from "react";
import "./VideoCard.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Rating, Tooltip, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IDoc, IUserData } from "../models/models";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
import { Link } from "react-router-dom";
import { handleFavouritesApi } from "../api/getData";
import { checkIsFavourite } from "../utlis/checkIsFavourite";

export interface IVideocard {
  docInfo: IDoc;
  alignment: boolean;
  typeOfDocs: string;
  userData: IUserData;
}

const VideoCard: React.FC<IVideocard> = ({
  docInfo,
  alignment,
  typeOfDocs,
  userData,
}) => {
  const [isFavourite, setIsFavourite] = useState<boolean>(
    checkIsFavourite(userData, docInfo, typeOfDocs) // Initialize based on current favourites
  );

  const isNew = (dateString: string) => {
    const createdAt = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - createdAt.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  };

  const handleFavourite = async (docInfo: IDoc) => {
    if (!userData?.isLogged) return;
    try {
      let favourite = {
        _id: docInfo?._id,
        title: docInfo?.title,
        image: docInfo?.image,
        type: typeOfDocs,
      };

      const action = isFavourite ? "delete" : "add";
      const response = await handleFavouritesApi(
        userData?.email,
        action,
        favourite
      );
      console.log(response);

      // Update local state to trigger re-render
      setIsFavourite((prev) => !prev);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log("An unknown error occurred.");
      }
    }
  };

  let videoCardHeight = alignment ? 380 : 256;
  let singleEndpointType = typeOfDocs === "docs" ? "singleDoc" : "singleTalk";

  return (
    <Link
      to={`/${singleEndpointType}/${docInfo?._id}`}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          maxWidth: 384,
          height: videoCardHeight,
          backgroundColor: "#F8F8F8",
          position: "relative",
        }}
        className="video-card"
      >
        <div style={{ position: "relative" }}>
          <CardMedia
            component="img"
            alt={docInfo?.title}
            height="160px"
            image={docInfo?.image}
          />
          {isNew(docInfo.createdAt) && (
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                backgroundColor: "white",
                color: "black",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "bold",
                opacity: 0.9,
              }}
            >
              New!
            </div>
          )}
          {docInfo?.subs && (
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "white",
                color: "black",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "bold",
                opacity: 0.9,
                border: "2px solid #fff",
                boxShadow: "0 0 10px rgba(255, 255, 255, 0.8)",
                animation: "glare 1.5s infinite",
              }}
            >
              Spanish subs!
            </div>
          )}
          <style>
            {`
    @keyframes glare {
      0% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 15px rgba(255, 255, 255, 0.6);
      }
      50% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 0.8);
      }
      100% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 15px rgba(255, 255, 255, 0.6);
      }
    }
  `}
          </style>
        </div>
        <CardContent>
          <Tooltip
            title={docInfo?.title}
            arrow
            placement="top"
            key={docInfo?.title.toString() + docInfo?.title.toString()}
          >
            <Typography
              sx={{
                marginTop: "-8px",
                fontWeight: "bold",
                fontSize: alignment ? "1.25rem" : "1rem", // Increased size for alignment true
                color: "#333", // Darker color for better readability
              }}
              gutterBottom
              variant={`${alignment ? "h6" : "subtitle1"}`}
              component="div"
            >
              {alignment ? docInfo.title : docInfo.title.substring(0, 30)}
              {docInfo.title.length > 34 && "..."}
              <h4
                style={{
                  fontSize: "0.9rem", // Slightly larger than before
                  color: "#757575", // Lighter gray for subtitle
                }}
              >
                {capitalizeFirstChar(docInfo?.genre)} - {docInfo.duration}min
              </h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    fontSize: "0.9rem", // Adjust font size for year
                    color: "#757575", // Consistent with genre color
                    marginRight: "6px", // Adds space between year and rating
                  }}
                >
                  ({docInfo.year})
                </h4>
                <Rating
                  value={docInfo.rating}
                  readOnly
                  max={5}
                  size="small"
                  sx={{ color: "#ff9800", marginTop: "-2px" }} // Customize rating star color
                />
              </div>
              {alignment && (
                <p
                  style={{
                    fontSize: "0.85rem", // Smaller, more subtle added date
                    color: "#9e9e9e", // Light gray for less emphasis
                    marginTop: "4px",
                  }}
                >
                  Added: {docInfo.createdAt.toString().substring(0, 10)}
                </p>
              )}
            </Typography>
          </Tooltip>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: alignment ? "0.875rem" : "0.75rem", // Increase font size for description
              color: "#616161", // Medium gray for better readability
            }}
          >
            {alignment && docInfo?.description.substring(0, 86)}
            {alignment && docInfo?.description?.length > 86 ? "..." : ""}
          </Typography>
        </CardContent>
        <CardActions>
          <div
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
            }}
          >
            <Tooltip
              title={
                userData?.isLogged
                  ? isFavourite
                    ? "Remove from favs"
                    : "Add to favorites"
                  : "Log in to add favorites"
              }
              placement="top"
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleFavourite(docInfo);
                }}
                sx={{
                  color: isFavourite ? "red" : "lightgrey", // Dynamic color
                }}
                aria-label="add to favorites"
              >
                <FavoriteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </CardActions>
      </Card>
    </Link>
  );
};

export default VideoCard;
