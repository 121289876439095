export const colorPalette = {
  backgroundWhite: "#f5f5f5",
};

export const barCustomStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? "black" : provided.borderColor, // Change border to black on focus
    boxShadow: state.isFocused ? "0 0 0 1px gray" : provided.boxShadow, // Adjust box shadow on focus
    "&:hover": {
      borderColor: state.isFocused ? "black" : provided.borderColor, // Ensure it stays black on hover when focused
    },
  }),
};
