import { IDoc } from "../models/models";

export const sortDocsBy = (docs: IDoc[], sortBy: string | null): IDoc[] => {
  let sortedDocs = [...docs];
  if (sortBy === "title") {
    sortedDocs = sortedDocs.sort((a, b) => a.title.localeCompare(b.title));
  }
  if (sortBy === "year") {
    sortedDocs = sortedDocs.sort((a, b) => b.year - a.year);
  }
  if (sortBy === "rating") {
    sortedDocs = sortedDocs.sort((a, b) => b.rating - a.rating);
  }
  if (sortBy === "genre") {
    sortedDocs = sortedDocs.sort((a, b) => a.genre.localeCompare(b.genre));
  }
  if (sortBy === "duration") {
    sortedDocs = sortedDocs.sort((a, b) => b.duration - a.duration);
  }
  if (sortBy === "added") {
    sortedDocs = sortedDocs.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }
  return sortedDocs;
};
