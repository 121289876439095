import React, { useState } from "react";
import "./App.css";
import { IDoc } from "./models/models";
import Navigator from "./navigation/Navigator";

function App() {
  const [docList, setDocList] = useState<IDoc[]>([]);

  return <Navigator docList={docList} setDocList={setDocList} />;
}

export default App;
