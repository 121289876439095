import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoDocsFound from "../components/NoDocsFound";
import { Alert, Box, Grid, Typography } from "@mui/material";

import { IDoc, IUserData } from "../models/models";
import Footer from "../components/Footer";
import TagVideoCard from "../components/TagVideoCard";
import { top20Array } from "../utlis/editorsItems";
import { checkIfUserIsLogged, getData, getTop20api } from "../api/getData";
import { Container } from "@mui/system";
import Loading from "../components/Loading";

interface IMovieRankingResults {
  allDocs: IDoc[];
  setTypeOfDocs: (type: string) => void;
  setDocList: (docList: IDoc[]) => void;
  setUserData: (userData: IUserData) => void;
}

const Top20: React.FC<IMovieRankingResults> = ({
  allDocs,
  setTypeOfDocs,
  setDocList,
  setUserData,
}) => {
  const [requestError, setRequestError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [top20List, setTop20List] = useState<IDoc[]>([]);

  const getTop20Docs = async () => {
    try {
      setIsLoading(true);

      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }

      const data = await getData("docs");
      if (!data) setRequestError(true);
      if (data) {
        let top20Data = await getTop20api();
        top20Data = top20Data.slice(0, 20);

        if (top20Data) {
          let finalTop20Data: IDoc[] = [];
          for (let i = 0; i < top20Data.length; i++) {
            finalTop20Data.push({
              ...data.find((doc: IDoc) => doc._id === top20Data[i].videoId),
              playCount: top20Data[i].playCount,
            });
          }
          setDocList(data);
          setTop20List(finalTop20Data);
        } else {
          setTop20List(data.slice(0, 20));
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setRequestError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTop20Docs();
    setTypeOfDocs("docs");
    window.scrollTo(0, 0);
  }, []);

  if (requestError)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>

        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  if (isLoading) return <Loading />;

  if (top20List.length === 0) return <NoDocsFound />;

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          marginBottom: "6px",
          textAlign: "center", // Center align the header
        }}
      >
        <Typography
          variant="h4" // Header size
          sx={{
            marginBottom: "20px", // Space below the header
            color: "black",
          }}
        >
          Top 20 Documentaries Most Watched by Users
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding={1}
        >
          {top20List.map((doc, index) => (
            <Grid key={doc._id} item xs={12} md={6}>
              <Box position="relative">
                <Typography
                  variant="h4" // Smaller font size
                  sx={{
                    position: "absolute",
                    top: -8, // Less margin from the top
                    left: -2,
                    color: "#ffffff",
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    padding: "4px 8px", // Smaller padding
                    borderRadius: "4px",
                    zIndex: 1,
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {index + 1} {/* Display ranking index */}
                </Typography>
                <TagVideoCard
                  key={doc._id}
                  docInfo={doc}
                  alignment={"top20"}
                  playcounts={doc.playCount}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {top20List.length === 1 && <div style={{ height: "300px" }} />}
      {top20List.length === 2 && <div style={{ height: "100px" }} />}
      <Footer />
    </>
  );
};

export default Top20;
