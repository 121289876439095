import React, { useState } from "react";
import Select from "react-select";
import { barCustomStyles } from "../styles/colorPalette";

interface Option {
  value: string;
  label: string;
}

export interface IAppDropdown {
  setSortBy: (sortBy: string) => void;
}

const AppDropdown: React.FC<IAppDropdown> = ({ setSortBy }) => {
  const options: Option[] = [
    { value: "title", label: "Title" },
    { value: "rating", label: "Rating" },
    { value: "year", label: "Year" },
    { value: "genre", label: "Genre" },
    { value: "duration", label: "Duration" },
    { value: "added", label: "Date added" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setSortBy(selectedOption?.value);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      placeholder="Sort by:"
      isClearable
      isSearchable={false}
      styles={barCustomStyles}
    />
  );
};

export default AppDropdown;
