import React from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sendSubscribe } from "../api/getData";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import { IUserData } from "../models/models";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface IUserSubscribeProps {
  userData: IUserData;
  setUserData: (userData: IUserData) => void;
}

const UserSubscribe: React.FC<IUserSubscribeProps> = ({
  userData,
  setUserData,
}) => {
  const [responseMessage, setResponseMessage] = React.useState<string | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState<boolean | null>(
    null
  );
  const [loginOrSubscribe, setLoginOrSubscribe] =
    React.useState<string>("login");
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const handleAction = async (data: any, action: string) => {
    setIsLoading(true);
    try {
      const postData = { action, ...data };
      const response = await sendSubscribe(postData);
      console.log(response);
      if (response && response.message) {
        setResponseMessage(data?.email + ": " + response.message);
        setErrorMessage(null);
        reset();

        setUserData(response?.user);

        localStorage.setItem("userEmail", response?.user?.email);

        const timeoutId = setTimeout(() => {
          navigate("/");
        }, 100);

        return () => clearTimeout(timeoutId);
      } else {
        console.log("Subscription successful, but no message received.");
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message || "An unexpected error occurred.");
        setResponseMessage(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribeClick = () => {
    setLoginOrSubscribe("subscribe");
  };

  const handleLoginClick = () => {
    setLoginOrSubscribe("login");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Watch password fields for real-time validation
  const password = watch("password", "");
  const confirmPassword = watch("confirmPassword", "");

  React.useEffect(() => {
    if (password && confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    } else {
      setPasswordsMatch(null);
    }
  }, [password, confirmPassword]);

  // return (
  //   <>
  //     <ContactForm />
  //     <Footer />
  //   </>
  // );

  return (
    <>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          {loginOrSubscribe === "login" ? "Login" : "Sign up"}
        </Typography>
        <Typography>
          {loginOrSubscribe === "login"
            ? "Log in to start building your personalized collection of favorite docs. No email spamming, emails drops only when top rated doc is added."
            : "Sign up to start building your personalized collection of favorite docs."}
        </Typography>
        <form
          onSubmit={handleSubmit((data) =>
            handleAction(data, loginOrSubscribe)
          )}
        >
          {/* Email Input */}
          <TextField
            label="Your Email"
            variant="outlined"
            fullWidth
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? (errors?.email?.message as string) : ""}
            margin="normal"
          />

          {/* Password Input */}
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters long",
              },
            })}
            error={!!errors.password}
            helperText={
              errors.password ? (errors?.password?.message as string) : ""
            }
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {loginOrSubscribe === "subscribe" && (
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              {...register("confirmPassword", {
                required: "Please confirm your password",
              })}
              error={!!errors.confirmPassword || passwordsMatch === false}
              helperText={
                errors.confirmPassword
                  ? (errors?.confirmPassword?.message as string)
                  : passwordsMatch === false
                  ? "Passwords do not match"
                  : ""
              }
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {passwordsMatch === true && (
            <Typography sx={{ color: "green", marginTop: "10px" }}>
              Passwords match!
            </Typography>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#111111" }}
              disabled={isLoading || passwordsMatch === false}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : loginOrSubscribe === "login" ? (
                "Login"
              ) : (
                "Subscribe"
              )}
            </Button>

            {loginOrSubscribe === "subscribe" && (
              <Button
                type="submit"
                variant="outlined"
                style={{ color: "#111111", borderColor: "#111111" }}
                onClick={handleSubmit((data) =>
                  handleAction(data, "unsubscribe")
                )}
                disabled={isLoading || passwordsMatch === false}
              >
                Unsubscribe
              </Button>
            )}
          </div>
        </form>
        {responseMessage && (
          <Typography sx={{ color: "green", marginTop: "10px" }}>
            {responseMessage}!
          </Typography>
        )}
        {errorMessage && (
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </Typography>
        )}

        {loginOrSubscribe === "login" ? (
          <Typography sx={{ marginTop: "16px" }}>
            Dont have account yet? Click here to subscribe.
            <Button onClick={handleSubscribeClick}>Sign up</Button>
          </Typography>
        ) : (
          <Typography sx={{ marginTop: "16px" }}>
            Already have an account? Login here.
            <Button onClick={handleLoginClick}>Login</Button>
          </Typography>
        )}
      </Container>
      <div style={{ height: "98px" }} />
      <ContactForm />
      <Footer />
    </>
  );
};

export default UserSubscribe;
