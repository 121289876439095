import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { IDoc } from "../models/models";
import { useNavigate } from "react-router-dom";

export interface IMoreLikeThis {
  docList: IDoc[];
  tags: string[];
  singleId: string;
  typeOfDocs: string;
}

const MoreLikeThis: React.FC<IMoreLikeThis> = ({
  docList,
  tags,
  singleId,
  typeOfDocs,
}) => {
  const [related, setRelated] = useState<IDoc[]>([]);
  const navigate = useNavigate();
  let urlEndpointType = "singleDoc";
  if (typeOfDocs === "talks") urlEndpointType = "singleTalk";

  const handleClick = (docId: string) => {
    navigate(`/${urlEndpointType}/${docId}`);
  };

  useEffect(() => {
    let moreLikeThisList: IDoc[] = [];
    if (tags) {
      for (let i = tags.length - 1; i >= 0; i--) {
        for (let j = 0; j < docList.length; j++) {
          if (
            docList[j].tags.includes(tags[i]) &&
            docList[j]._id !== singleId &&
            !moreLikeThisList.includes(docList[j])
          ) {
            if (moreLikeThisList.length >= 4) break;
            moreLikeThisList.push(docList[j]);
          }
        }
      }
    }
    setRelated(moreLikeThisList);
  }, [tags, docList]);

  if (related?.length === 0) return <div></div>;
  return (
    <div style={{ marginTop: "28px" }}>
      <Typography variant="h6" gutterBottom>
        More like this:
      </Typography>
      <Grid container spacing={2}>
        {related.map((movie) => (
          <Grid item xs={6} sm={4} md={3} key={movie._id}>
            <Card
              onClick={() => handleClick(movie._id)}
              sx={{
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  cursor: "pointer",
                },
              }}
            >
              <CardMedia
                component="img"
                image={movie.image}
                alt={movie.title}
                sx={{
                  objectFit: "cover",
                  height: { xs: 100, sm: 150 }, // Set height responsively using sx
                }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MoreLikeThis;
