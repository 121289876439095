import React from "react";
import { Box, Container, Typography, Link, Grid } from "@mui/material";

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#111111", p: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="white" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="white">
              Welcome to 4Seven Docs. We bring you hand picked documentaries
              about conspiracy, mystery, archeology, and other several themes.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="white" gutterBottom>
              Get in Touch
            </Typography>
            <Typography variant="body2" color="white">
              Got questions? Drop us a line at{" "}
              <Link href="https://mail.google.com/mail/?view=cm&fs=1&to=4sevendocs@gmail.com">
                4sevendocs@gmail.com
              </Link>
            </Typography>
            <Box sx={{ mt: 2 }}></Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="body2" color="white">
            {`© ${year} / 4Seven Docs. `}
          </Typography>
          <Box></Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
