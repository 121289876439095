import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface LoadingProps {
  text?: string; // Optional text to display below the spinner
  size?: number; // Optional size for the spinner
  color?: 'inherit' | 'primary' | 'secondary'; // Optional color for the spinner
}

const Loading: React.FC<LoadingProps> = ({ text = 'Loading...', size = 50, color = 'primary' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="60vh" // Full viewport height
    >
      <CircularProgress size={size} color={color} />
      {text && (
        <Typography variant="h6" color="textSecondary" marginTop={2}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;
