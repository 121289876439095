import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import NoDocsFound from "../components/NoDocsFound";
import { Box, Grid } from "@mui/material";
import VideoCard from "../components/VideoCard";
import { IDoc } from "../models/models";
import TagVideoCard from "../components/TagVideoCard";
import Footer from "../components/Footer";

interface ITagSearchResults {
  allDocs: IDoc[];
  setNumberOfDocs: (numberOfDocs: number) => void;
}

const TagSearchResults: React.FC<ITagSearchResults> = ({
  allDocs,
  setNumberOfDocs,
}) => {
  const { tag } = useParams<{ tag: string }>();

  const docsList = allDocs.filter((doc) => doc.tags.includes(tag as string));

  useEffect(() => {
    window.scrollTo(0, 0);
    setNumberOfDocs(docsList.length);
  }, []);

  if (docsList.length === 0) return <NoDocsFound />;

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          marginBottom: "6px",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding={1}
        >
          {docsList.map((doc) => {
            return (
              <Grid key={doc._id} item xs={12}>
                <TagVideoCard
                  key={doc._id}
                  docInfo={doc}
                  alignment={"-detail"}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {docsList.length === 1 && <div style={{ height: "194px" }} />}
      {docsList.length === 2 && <div style={{ height: "44px" }} />}
      <Footer />
    </>
  );
};

export default TagSearchResults;
