import React from "react";
import Box from "@mui/material/Box";
import { IUserData } from "../models/models";
import { checkIfUserIsLogged } from "../api/getData";
import { useNavigate } from "react-router-dom";

interface IProfileCircleProps {
  email: string;
  setUserData: (userData: IUserData) => void;
  userData: IUserData;
}

const ProfileCircle: React.FC<IProfileCircleProps> = ({
  email,
  setUserData,
  userData,
}) => {
  // Get the first letter of the email
  const navigate = useNavigate();
  const getFirstLetter = (email: string) => email.charAt(0).toUpperCase();

  const logout = async () => {
    try {
      const response = await checkIfUserIsLogged(userData.email, "logout");
      console.log(response);
      if (response.isLogged === false) {
        setUserData({ ...userData, favourites: [], isLogged: false });
        navigate("/subscribe");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1, // Add some space between the circle and "Logout"
      }}
      onClick={logout}
    >
      <Box
        sx={{
          width: 21,
          height: 21,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "grey",
          color: "white",
          fontSize: "0.875rem",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {getFirstLetter(email)}
      </Box>
      <Box
        sx={{
          fontSize: "0.875rem",
          fontWeight: "bold",
          cursor: "pointer", // Optional, makes the "Logout" look clickable
        }}
      >
        Logout
      </Box>
    </Box>
  );
};

export default ProfileCircle;
