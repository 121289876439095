import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

import { IBook, IUserData } from "../models/models";
import NoDocsFound from "../components/NoDocsFound";
import { checkIfUserIsLogged, getData } from "../api/getData";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { Alert } from "@mui/material";
import Footer from "../components/Footer";

interface IBooksGridProps {
  setUserData: (userData: IUserData) => void;
  userData: IUserData;
}

const BooksGrid: React.FC<IBooksGridProps> = ({ setUserData, userData }) => {
  const [bookList, setBookList] = useState<IBook[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);

  const navigate = useNavigate();

  const getAllBooks = async () => {
    try {
      setIsLoading(true);
      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }

      const data = await getData("books");
      if (!data) setRequestError(true);
      if (data) {
        setBookList(data);
      }
    } catch (err) {
      console.log(err);
      setRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBooks();
  }, []);
  bookList.map((b) => console.log(b.title));
  if (isLoading) return <Loading text="Loading your books..." />;

  if (requestError)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>

        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  return (
    <Box
      sx={{ flexGrow: 1, marginBottom: "6px", backgroundColor: "transparent" }}
    >
      <h3 style={{ marginLeft: "10px" }}>Suggested Books</h3>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {bookList.map((book) => (
          <Grid key={book._id} xs={12} sm={6} md={4} lg={3}>
            {/* Box acts as the link */}
            <Box
              onClick={() => navigate(`/singleBook/${book._id}`)}
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                height: "250px",
                borderRadius: "8px",
                boxShadow: 2,
                overflow: "hidden",
                backgroundColor: "#f5f5f5",
                textDecoration: "none",
              }}
            >
              {/* Image Section */}
              <Box
                component="img"
                src={book?.image}
                alt={book?.title}
                sx={{
                  width: "46%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              {/* Info Section */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                }}
              >
                {/* Title */}
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                    fontWeight: "bold",
                    pointerEvents: "none", // Ensures title doesn't appear clickable
                  }}
                >
                  {book.title}
                </Typography>

                {/* Info at Bottom */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Author: {book?.author}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Genre: {book?.genre}
                  </Typography>
                  {/* Rating */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{ marginRight: "8px", pointerEvents: "none" }}
                    >
                      Rating:
                    </Typography>
                    <Rating
                      name="book-rating"
                      value={book?.rating}
                      precision={0.5}
                      size="small"
                      readOnly
                      sx={{ pointerEvents: "none" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Footer />
    </Box>
  );
};

export default BooksGrid;
