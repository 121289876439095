import React from "react";
import { getRandomLink } from "../utlis/randomLink";

interface AddModalProps {
  isVisible: boolean;
  onClose: () => void;
}

function AddModalNewTab({ isVisible, onClose }: AddModalProps) {
  const isMobile = window.innerWidth <= 768;
  const randomAddLink = getRandomLink();

  const modalOverlayStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    zIndex: 1000,
    opacity: 0.1,
    cursor: "pointer",
    borderRadius: "6px",
  };

  const modalContainerStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
    zIndex: 1001,
    textDecoration: "none",
  };

  const headerStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 1)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0",
    fontSize: isMobile ? "12px" : "18px",
    fontWeight: "bold",
    zIndex: 1002,
    marginLeft: 2,
  };

  const textContainerStyle: React.CSSProperties = {
    marginTop: "60px",
    textAlign: "center",
    padding: "5px",
  };

  const textStyle: React.CSSProperties = {
    fontSize: isMobile ? "12px" : "22px",
    color: "white",
    textDecoration: "none",
    textAlign: "center",
    marginTop: isMobile ? "0%" : "20%",
  };

  return (
    <>
      {isVisible && (
        <a
          href={randomAddLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose} // Close modal after opening link
          style={{ textDecoration: "none" }}
        >
          <div style={modalOverlayStyle} />

          <div style={headerStyle}>
            Please wait until the ad loads, it will help us keep the site free
            and minimize ads.
          </div>

          <div style={modalContainerStyle}>
            <div style={textContainerStyle}></div>
          </div>
        </a>
      )}
    </>
  );
}

export default AddModalNewTab;
