import "./VideoCard.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Rating } from "@mui/material";
import { IDoc } from "../models/models";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/system";

export interface IVideocard {
  docInfo: IDoc;
  alignment: string;
  playcounts?: number;
}

const TagVideoCard: React.FC<IVideocard> = ({
  docInfo,
  alignment,
  playcounts,
}) => {
  const videoCardHeight = alignment === "+ detail" ? 360 : 260;

  const isNew = (dateString: string) => {
    const createdAt = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - createdAt.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  };

  return (
    <Link to={`/singleDoc/${docInfo?._id}`} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          height: "128px",
          overflow: "hidden",
          boxShadow: 3,
          borderRadius: 2,
          position: "relative",
        }}
        className="video-card"
      >
        <CardMedia
          component="img"
          alt={docInfo?.title}
          image={docInfo?.image}
          sx={{ width: "25%", height: "100%", objectFit: "cover" }}
        />

        {/* Overlay play count and icon */}
        {alignment === "top20" && (
          <Box
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <VisibilityIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
            <Typography variant="body2">{playcounts}</Typography>
          </Box>
        )}

        {isNew(docInfo.createdAt) && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "white",
              color: "black",
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "bold",
              opacity: 0.9,
            }}
          >
            New!
          </Box>
        )}

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "70%",
            overflow: "hidden",
          }}
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {docInfo.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
          >
            <span>
              {docInfo.duration}min / {capitalizeFirstChar(docInfo?.genre)}
            </span>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              alignSelf: alignment === "top20" ? "center" : "left",
            }}
          >
            <span style={{ fontSize: "14px", color: "grey" }}>
              ({docInfo.year})
            </span>
            <Rating value={docInfo.rating} readOnly max={5} size="small" />
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

export default TagVideoCard;
