export const editorsItems = [
  {
    id: 1,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/27KY9R5THMXl_320x180.jpg",
    title: "Missing 411",
    navigationId: "448",
  },
  {
    id: 2,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/Mthr45FPufIs_320x180.jpg  ",
    title: "Monopoly",
    navigationId: "444",
  },
  {
    id: 3,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/y8Xl7KAWo9Ud_320x180.jpg",
    title: "Diddy",
    navigationId: "436",
  },
  {
    id: 4,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/CRRMzdjelBzz_320x180.jpg",
    title: "The Primacy of Consciousness",
    navigationId: "405",
  },
  {
    id: 5,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/pa3dTawgJOen/89DFm4NdpJXf_320x180.jpg",
    title: "Samadhi 3",
    navigationId: "199",
  },
  {
    id: 6,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/orcYb6j7cNkI_320x180.jpg",
    title: "Everything Is a Rich Man’s Trick",
    navigationId: "151",
  },
];

export const top20Array = [
  204, 205, 409, 330, 406, 151, 396, 405, 331, 361, 403, 343, 267, 399, 402,
  351, 373, 352, 1, 357,
];
