import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";

interface IAppSearch {
  searchTitles: { title: string; _id: string }[];
}

const AppSearch: React.FC<IAppSearch> = ({ searchTitles }) => {
  const titlesForSearch = searchTitles.map((title) => title.title);

  const navigate = useNavigate();

  const handleChange = (value: string | null) => {
    let findIdToNavigate;
    if (value) {
      findIdToNavigate = searchTitles.find((title) => title.title === value);
    }

    if (findIdToNavigate?._id) navigate(`/singleDoc/${findIdToNavigate._id}`);
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={titlesForSearch}
      onChange={(event, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by title"
          sx={{
            height: "20px",
            "& .MuiOutlinedInput-root": {
              height: "37.5px",
              backgroundColor: "white",
              "&.Mui-focused fieldset": {
                borderColor: "gray", // Change border color on focus
              },
            },
            "& .MuiInputLabel-root": {
              marginTop: "-10px", // Adjust label's margin
            },
          }}
        />
      )}
    />
  );
};

export default AppSearch;
