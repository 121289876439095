export function getRandomLink(): string {
  // const links = [
  //   "https://impropermoleculeshilling.com/ych7n7m70h?key=f9f83db0b13dc1aed5325abaddf9a3c1",
  //   "https://impropermoleculeshilling.com/tcdwkz0zh?key=b34c02ea18770d80ea1e3f01d6b10598",
  //   "https://impropermoleculeshilling.com/gdskr2id?key=3687c27e0985df79e7b6dc0c11894f39",
  //   "https://impropermoleculeshilling.com/e1tc5geza?key=35ad9410f6bbe85b56a3a668fb44e71e",
  //   "https://impropermoleculeshilling.com/abjyn9e5?key=ba0dae0f5a27cbdbd8220f7e8e479196",
  //   "https://impropermoleculeshilling.com/aiv5mc2khs?key=3082f16463fad8199d36023e30a67b44",
  //   "https://impropermoleculeshilling.com/amyqe515?key=fe08538ea16276db7ad2461d4467e9ea",
  //   "https://impropermoleculeshilling.com/rignbc7t0?key=d2922e0cf1941435ad52f307bf2c0652",
  //   "https://impropermoleculeshilling.com/de82nndtz5?key=19c1fa727a7664c85000f26ccd9fd521",
  //   "https://impropermoleculeshilling.com/cybv7544n?key=374595c78decad452099560f0ec5dd43",
  // ];
  // const links = [
  //   "https://handmadetip.com/bF3aVD0/P.3zpyv_bBmvVgJeZuDh0B1/NzzKcozcNjDeYL4zLtT/Uc3ENUzcMo0/NZjYkS",
  //   "https://handmadetip.com/b_3.VD0fPA3CpVvtb_maVtJtZUDI0X1pNJzXcWz/NTDikHyAL/T/U/3MNjzzMp0BOpTIMy",
  //   "https://handmadetip.com/bp3gVm0.PP3apNvtbXmuVGJHZ-D/0K1tOPDIEK1xNED/UO1/LNTuU/4KM/TyUZ0QNVTnYw",
  //   "https://handmadetip.com/b.3QVi0WPP3RprvebMm/VbJ/ZADI0L1COdDWEq1oN/DTYezLL/TfUb4QMeT/Uj0bNDjIQ_",
  // ];

  const links = [
    "https://zireemilsoude.net/4/8583539",
    "https://zireemilsoude.net/4/8583536",
    "https://zireemilsoude.net/4/8583532",
    "https://zireemilsoude.net/4/8585306",
    "https://zireemilsoude.net/4/8585307",
    "https://luglawhaulsano.net/4/8608915",
    "https://luglawhaulsano.net/4/8608916",
    "https://luglawhaulsano.net/4/8608917",
    "https://luglawhaulsano.net/4/8608918",
    "https://luglawhaulsano.net/4/8608919",
    "https://luglawhaulsano.net/4/8646258",
    "https://luglawhaulsano.net/4/8646257",
    "https://luglawhaulsano.net/4/8646255",
    "https://luglawhaulsano.net/4/8646256",
    "https://luglawhaulsano.net/4/8646254",
  ];

  const randomIndex = Math.floor(Math.random() * links.length);
  return links[randomIndex];
}
